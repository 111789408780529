import React from 'react'
import Layout from "../components/layout"
import claudioJoy from '../images/claudio-joy.png'
import SEO from '../components/seo'

export default function MayorWelcomes() {
    return (
        <Layout>
            <SEO title="Claudio, on finding new joy" image={claudioJoy} />
            <h1>
                Claudio, on finding new joy
            </h1>
            <img src={claudioJoy} />
            <p>"Sometimes you have to face the utter darkness of night to experience the joy of basking in the morning sun."</p>
            
            <p>Our spirits are lifted to see Claudio coming out of his “utter darkness” and into a new day. As this humble correspondent can attest, there is indeed life after love. May you find love again, dear Claudio!</p>
        </Layout>
    )
}
